.ant-table-thead > tr > th {
  padding: 8px;
  .ant-table-column-sorters {
    padding: 4px;
  }
}

.ant-table-tbody > tr > td {
  padding: 4px;
}

tfoot.ant-table-summary  > tr > td {
  padding: 6px;
}

.ant-tooltip-content {
  min-width: 100%;
  white-space: pre-wrap;
}

.symbol-ref-tooltip {
  .ant-tooltip-inner {
    min-width: 320px;
  }
}

#mapTable-tabs {
  .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-tab {
    width: 200px;
    text-align: center;
  }
}

.map-header-title {
  font-weight: 500;
}

.ant-popover-title {
  text-align: center;
}

.text-center {
  text-align: center;
}
@primary-color: #1DA57A;